import { datadogRum } from '@datadog/browser-rum';
import { GatsbyBrowser } from 'gatsby';
import DD_OPTIONS from '@ecs/tng-common/src/scripts/data-dog-options';
import { setWindowElements } from '@ecs/tng-common/src/scripts/helpers';

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
  if (!prevLocation && !location.hash) {
    // do not restore scroll position on page refresh without hash
    setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'auto' }), 0);
  }
  setTimeout(() => {
    if (prevLocation && location && location !== prevLocation) {
      setWindowElements();
      if (window.instantiatePage) {
        window.instantiatePage();
      }
    }
  }, 20);
};

export const onInitialClientRender = () => {
  // add custom event to window to signal t&t that React hydration is complete
  const event = new CustomEvent('gatsbyHydrationComplete', {
    bubbles: true,
    cancelable: false
  });
  window.dispatchEvent(event);
  window.gatsbyHydrationComplete = true;

  // setWindowElements();
  // run dataDog script
  if (process.env.GATSBY_ENV !== 'development' && datadogRum) {
    datadogRum.init(DD_OPTIONS);
    datadogRum.startSessionReplayRecording();
  }
};
export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location }
}) => {
  window.history.scrollRestoration = 'manual';

  setTimeout(() => {
    if (location.hash) {
      // If hash (`/#something`), smooth scroll to that position
      const item = document.querySelector(`${location.hash}`);
      item?.scrollIntoView({ behavior: 'auto' });
    } else {
      window.scrollTo(0, 0);
    }
  }, 0);

  // do not let gatsby do anything more
  return false;
};
