exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-credit-cards-tsx": () => import("./../../../src/pages/credit-cards.tsx" /* webpackChunkName: "component---src-pages-credit-cards-tsx" */),
  "component---src-pages-loans-tsx": () => import("./../../../src/pages/loans.tsx" /* webpackChunkName: "component---src-pages-loans-tsx" */),
  "component---src-templates-cards-category-template-tsx": () => import("./../../../src/templates/cards-category-template.tsx" /* webpackChunkName: "component---src-templates-cards-category-template-tsx" */),
  "component---src-templates-cards-details-template-tsx": () => import("./../../../src/templates/cards-details-template.tsx" /* webpackChunkName: "component---src-templates-cards-details-template-tsx" */),
  "component---src-templates-cards-partner-template-tsx": () => import("./../../../src/templates/cards-partner-template.tsx" /* webpackChunkName: "component---src-templates-cards-partner-template-tsx" */),
  "component---src-templates-cards-sem-full-list-tsx": () => import("./../../../src/templates/cards-sem-full-list.tsx" /* webpackChunkName: "component---src-templates-cards-sem-full-list-tsx" */),
  "component---src-templates-loan-lps-template-tsx": () => import("./../../../src/templates/loan-lps-template.tsx" /* webpackChunkName: "component---src-templates-loan-lps-template-tsx" */),
  "component---src-templates-loan-providers-template-tsx": () => import("./../../../src/templates/loan-providers-template.tsx" /* webpackChunkName: "component---src-templates-loan-providers-template-tsx" */)
}

